import React, { useEffect, useState } from 'react';
import { Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Table } from '../Table';
import { useApi } from '../../hooks';
import { Download, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { config } from '../../providers/config';

const Participaciones = (props) => {
  const navigate = useNavigate();
  const [participaciones, setParticipaciones] = useState([]);
  const [dateInit, setDateInit] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs().add(1, 'days'));
  const [channel, setChannel] = useState("Todos");

  const [getParticipaciones] = useApi({
    endpoint: 'barcode/all',
    method: 'get'
  });


  const getParticipacionesList = async () => {
    const startDate = dateInit.toDate()
    startDate.setHours(0, 0, 0, 0);

    const endDate = dateEnd.toDate();
    endDate.setHours(23, 59, 59, 999);

    let channelSearch = channel
    if (channel == "Todos") {
      channelSearch = ""
    }

    const response = await getParticipaciones({
      queryString: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        channel: channelSearch
      }
    });

    response.data.map(
      (item) =>
        (item.createdDate = moment(item.createdDate).format('DD/MM/YYYY HH:mm:SS'))
    );

    setParticipaciones(response.data)
  };

  const getParticipacionesListDownload = async () => {
    const startDate = dateInit.toDate()
    startDate.setHours(0, 0, 0, 0);

    const endDate = dateEnd.toDate();
    endDate.setHours(23, 59, 59, 999);

    let channelSearch = channel
    if (channel == "Todos") {
      channelSearch = ""
    }

    const queryString = new URLSearchParams({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      channel: channelSearch
    }).toString();


    try {
      const response = await fetch(config.endpoints.mainBackendUrl + `/barcode/all/download?${queryString}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'participaciones.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Error al descargar el archivo:', response.statusText);
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };

  useEffect(() => {
    getParticipacionesList();
  }, []);


  const tableHead = [
    { key: 'participant', label: "Participante" },
    { key: 'contact', label: "Contacto" },
    { key: 'product', label: "Producto" },
    {
      key: 'code',
      label: "Código barras"
    },
    { key: 'lote', label: "Lote" },
    { key: 'channel', label: "Canal" },
    { key: 'points', label: "Puntos" },
    { key: 'status', label: "Estado" },
    { key: 'type', label: "Categoría" },
    { key: 'createdDate', label: "Fecha y hora" },
    { key: '', label: '' }
  ];

  const filters = [
    {
      key: 'participant',
      value: "Participante"
    }
  ];

  const configProps = {
    filters,
    actions: {
      customs: [{
        text: "Ver",
        icon: Visibility,
        onClick: (item) => { navigate(`/participaciones/${item.id}`) }
      }]
    },
    selectedOptions: {
      checkboxHidden: true
    },
    orderbyProp: 'createddate',
    orderProp: 'desc'
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item sx={6} md={2}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Desde"
            minDate={dayjs('2024-08-10')}
            maxDate={dateEnd}
            value={dateInit}
            onChange={(value) => { setDateInit(value) }}
          />
        </Grid>
        <Grid item sx={6} md={2}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Hasta"
            minDate={dateInit}
            maxDate={dayjs('20204-10-10')}
            value={dateEnd}
            onChange={(value) => { setDateEnd(value) }}
          />
        </Grid>
        <Grid item sx={6} md={2}>
          <FormControl fullWidth>
            <InputLabel id="select-label-status">Canal</InputLabel>
            <Select
              labelId="select-label-status"
              value={channel}
              label="Canal"
              onChange={(e) => {
                setChannel(e.target.value)
              }}
            >
              <MenuItem value={"Todos"}>Todos</MenuItem>
              <MenuItem value={"Web"} >Web</MenuItem>
              <MenuItem value={"Whatsapp"}>Whatsapp</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container item sx={6} md={2} alignItems={'center'}>
          <Button
            variant='contained'
            onClick={() => { getParticipacionesList() }}
          >Buscar</Button>
        </Grid>
        <Grid item sx={0} md={2}></Grid>
        <Grid item sx={12} md={2}>
          <Button
            variant='contained'
            startIcon={<Download />}
            onClick={() => { getParticipacionesListDownload() }}
          >
            Descargar
          </Button>
        </Grid>
      </Grid>
    <Table data={participaciones} columns={tableHead} configProps={configProps} />
    </>
  );
};


export { Participaciones };