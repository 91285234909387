import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Table } from '../Table';
import { useApi } from '../../hooks';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Usuarios = (props) => {
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);

  const [getUsuarios] = useApi({
    endpoint: 'auth/dash/all',
    method: 'get'
  });


  const getUsuariosList = async () => {
    const response = await getUsuarios();
    setUsuarios(response.data)
  };

  useEffect(() => {
    getUsuariosList();
  }, []);


  const tableHead = [
    { key: 'name', label: "Nombre" },
    {
      key: 'email',
      label: "Correo electrónico"
    }
  ];

  const filters = [
    {
      key: 'name',
      value: "Nombre",
    },
    { key: 'email', value: "Correo electrónico" },
  ];

  const configProps = {
    filters,
    actions: {
      customs: [{
        text: "Ver",
        icon: Visibility,
        onClick: (item) => { navigate(`/usuarios/${item.id}`) }
      }]
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div>
      <Table data={usuarios} columns={tableHead} configProps={configProps} />
    </div>
  );
};


export { Usuarios };