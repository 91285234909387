import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useStyles } from './TableOptionsStyles';

function Option(props) {
  const { item, text, onClick, hidden, disabled, icon, setIsOpen } = props;
  const classes = useStyles();

  const handleClick = () => {
    setIsOpen(false);

    if (onClick) return onClick(item);
  };

  if (hidden) return null;

  return (
    <MenuItem
      className={classes.item}
      disabled={disabled}
      onClick={handleClick}
    >
      {icon && (
        <ListItemIcon>
          <props.icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={text}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </MenuItem>
  );
}

export { Option };
