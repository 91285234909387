import React from 'react';
import Lottie from 'react-lottie-player';
import { Box, Typography } from '@mui/material';
import animationLoader from '../../assets/animations/loader.json';
import { useStyles } from './BasicLoaderStyles';
import './BasicLoader.scss';

const BasicLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Lottie
        loop
        play
        animationData={animationLoader}
        className={classes.animation}
        speed={1.5}
      />

      <Box mt={0}>
        <Typography variant="h6" className={classes.bottomText}>
          Cargando...
        </Typography>
      </Box>
    </div>
  );
};

export { BasicLoader };
