import React from 'react';
import { Typography, Button, TextField, InputAdornment, IconButton, Card, Grid, Container, CardContent } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Images } from '../../assets';
import { useStyles } from './LoginStyles';
import { useLogin } from './useLogin';



export const Login = () => {

  const classes = useStyles();
  const { formik, password, setPassword } = useLogin();

  return (
    <div className={classes.container}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <div className={classes.formContainer}>
                  <img
                    src={Images.companyLogo}
                    alt="companyLogo"
                    style={{ margin: 3 }}
                  />

                  <Typography
                    variant="h4"
                    color="primary"
                    className={classes.instructions}
                    align='center'
                  >
                    Acceso
                  </Typography>
                  <TextField
                    style={{ width: '100%' }}
                    margin='normal'
                    id="email-field"
                    label="Correo"
                    name="email"
                    placeholder={"Ej. demo@demo.com"}
                    value={formik.values.email}
                    onChange={(value) => { formik.setFieldValue("email", value.target.value) }}
                    error={formik.touched.email && formik.errors.email}
                    helperText={formik.errors.email}
                    variant='outlined'
                  />

                  <TextField
                    style={{ width: '100%' }}
                    margin='normal'
                    id="pass-field"
                    label="Contraseña"
                    type={password ? 'text' : 'password'}
                    name="password"
                    placeholder={"Ej. J$Kyq120"}
                    value={formik.values.password}
                    onChange={(value) => { formik.setFieldValue("password", value.target.value) }}
                    error={formik.touched.password && formik.errors.password}
                    helperText={formik.errors.password}
                    variant='outlined'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { setPassword(!password) }}
                          >
                            {password ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.submitForm();
                      }
                    }}
                  />

                  <Button
                    className={classes.submitButton}
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => { formik.submitForm() }}
                  >
                    Acceso
                  </Button>

                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0} md={3}></Grid>
        </Grid>
      </Container>

    </div>
  )
}
