import {
  Logout,
  EmojiEvents,
  Checklist,
  Group,
  Analytics,
  Redeem,
  FolderShared
} from '@mui/icons-material';
import { useAuth } from '../../hooks';

export const useLists = () => {
  const auth = useAuth();

  const adminSection =
    [
      {
        name: 'dashboard',
        path: '/',
        message: 'Dashboard',
        icon: Analytics
      },
      {
        name: 'ranking',
        path: '/ranking',
        message: 'Ranking',
        icon: EmojiEvents
      },

      {
        name: 'participaciones',
        path: '/participaciones',
        message: 'Participaciones',
        icon: Checklist
      },

      {
        name: 'participanates',
        path: '/participantes',
        message: 'Participantes',
        icon: FolderShared
      },
      // {
      //   name: 'premios',
      //   path: '/premios',
      //   message: 'Premios',
      //   icon: Redeem
      // },
      {
        name: 'usuarios',
        path: '/usuarios',
        message: 'Usuarios',
        icon: Group
      }
    ]
    ;

  return [
    {
      title: "Administración",
      items: [...adminSection,
      {
        name: 'logout',
        message: "Cerrar sesión",
        icon: Logout,
        action: () => auth.actions.logout()
      }
      ]
    }
  ];
};
