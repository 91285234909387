import React from 'react';
import { Box, Typography } from '@mui/material';

function SearchNotFound({ searchQuery = '', ...other }) {

  return (
    <Box {...other}>
      <Typography
        gutterBottom
        align="center"
        variant="body1"
        style={{ fontWeight: 'bold' }}
      >
        {searchQuery !== '' ? 'Sin coincidencias' : 'Sin registros'}
      </Typography>
      <Typography variant="body2" align="center">
        {searchQuery !== '' && (
          <>
            No se encontraron resultados para
            <strong>&quot;{searchQuery}&quot;</strong>.
            Intente comprobar si hay errores tipográficos o utilice palabras completas.
          </>
        )}
      </Typography>
    </Box>
  );
}

export { SearchNotFound };
