import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registrar los componentes que vas a usar
ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const BarParticipacionChart = ({ labels, data }) => {
  const dataBar = {
    labels: labels,
    datasets: [
      {
        borderColor: 'rgba(46, 128, 153, 1)',
        backgroundColor: 'rgba(46, 128, 153, .5)',
        data: data.daysParticipaciones
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Participaciones por día',
      },
    },
  };

  return <Bar data={dataBar} options={options} style={{ maxHeight: 400 }} />;
};


export { BarParticipacionChart };