import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Paper, Button, Typography } from '@mui/material';
import { useApi } from '../../hooks';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BarChart } from '../BarChart';
import { BarLineChart } from '../BarLineChart';
import { BarParticipacionChart } from '../BarParticipacionChart';
import { BartLineChartGeneric } from '../BartLineChartGeneric';
import { StackBarChart } from '../StackBarChart';
import { PolarChart } from '../PolarChart';
import dayjs from 'dayjs';
import { WhatsApp, Laptop } from '@mui/icons-material';

const Dashboard = (props) => {

  const [metricts, setMetricts] = useState({});
  const [dateInit, setDateInit] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const [whastappState, setWhastappState] = useState([]);
  const [WebState, setWebState] = useState([]);
  const [whastappDay, setWhastappDay] = useState([]);
  const [WebDay, setWebDay] = useState([]);
  const [labelsDay, setLabelsDay] = useState([]);
  const [newSuscribers, setNewSuscribers] = useState([]);
  const [newConversations, setNewConversations] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [productsDataset, setProductsDataset] = useState([]);
  const [botAnalytics, setBotAnalytics] = useState({});


  const [getMetricts] = useApi({
    endpoint: 'barcode/metricts',
    method: 'get'
  });


  const getMetrictsDetail = async () => {
    const startDate = dateInit.toDate()
    startDate.setHours(0, 0, 0, 0);

    const endDate = dateEnd.toDate();
    endDate.setHours(23, 59, 59, 999);

    const response = await getMetricts({
      queryString: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
    });

    let statesParticipacionesWhats = []
    let statesParticipacionesWeb = []
    let statesParticipantesWhats = []
    let statesParticipantesWeb = []
    let historyState = response.data.historyState || []
    statesList.map(state => {
      let finded = historyState.find(x => x.state == state)
      if (finded) {
        statesParticipacionesWhats.push(finded.participaciones_whatsapp)
        statesParticipacionesWeb.push(finded.participaciones_web)
        statesParticipantesWhats.push(finded.participantes_web)
        statesParticipantesWeb.push(finded.participantes_whatsapp)
      } else {
        statesParticipacionesWhats.push(0)
        statesParticipacionesWeb.push(0)
        statesParticipantesWhats.push(0)
        statesParticipantesWeb.push(0)
      }
    })

    setWhastappState({ statesParticipaciones: statesParticipacionesWhats, statesParticipantes: statesParticipantesWhats })
    setWebState({ statesParticipaciones: statesParticipacionesWeb, statesParticipantes: statesParticipantesWeb })


    let labelsDays = generarFechasEnRango(startDate, endDate)
    let daysParticipacionesWhats = []
    let daysParticipacionesWeb = []
    let daysParticipantesWhats = []
    let daysParticipantesWeb = []
    let suscribers = []
    let conversations = []
    let messages = []
    let hitoryDate = response.data.hitoryDate || []
    let historyNewSuscribers = response.data.historyNewSuscribers || []
    let historyNewConversations = response.data.historyNewConversations || []
    let historyMessage = response.data.historyMessage || []
    let historyProducts = response.data.historyByProducts || []

    let activia = []
    let beng = []
    let danette = []
    let danmix = []
    let danone = []
    let danonino = []
    let danup = []
    let dany = []
    let oikos = []
    let vitalinea = []

    labelsDays.map(day => {
      let finded = hitoryDate.find(x => x.Fecha === day)
      if (finded) {
        daysParticipacionesWhats.push(finded.participaciones_whatsapp)
        daysParticipacionesWeb.push(finded.participaciones_web)
        daysParticipantesWhats.push(finded.participantes_web)
        daysParticipantesWeb.push(finded.participantes_whatsapp)
      } else {
        daysParticipacionesWhats.push(0)
        daysParticipacionesWeb.push(0)
        daysParticipantesWhats.push(0)
        daysParticipantesWeb.push(0)
      }

      let findedProductsDay = historyProducts.find(x => x.Fecha === day)
      if (findedProductsDay) {
        activia.push(findedProductsDay.activia)
        beng.push(findedProductsDay.beng)
        danette.push(findedProductsDay.danette)
        danmix.push(findedProductsDay.danmix)
        danone.push(findedProductsDay.danone)
        danonino.push(findedProductsDay.danonino)
        danup.push(findedProductsDay.danup)
        dany.push(findedProductsDay.dany)
        oikos.push(findedProductsDay.oikos)
        vitalinea.push(findedProductsDay.vitalinea)
      } else {
        activia.push(0)
        beng.push(0)
        danette.push(0)
        danmix.push(0)
        danone.push(0)
        danonino.push(0)
        danup.push(0)
        dany.push(0)
        oikos.push(0)
        vitalinea.push(0)
      }

      let findedSuscribers = historyNewSuscribers.find(x => x.fecha === day)
      if (findedSuscribers) {
        suscribers.push(findedSuscribers.cantidad)
      } else {
        suscribers.push(0)
      }

      let findedConversation = historyNewConversations.find(x => x.Fecha === day)
      if (findedConversation) {
        conversations.push(findedConversation.total)
      } else {
        conversations.push(0)
      }

      let findedMsg = historyMessage.find(x => x.Fecha === day)
      if (findedMsg) {
        messages.push(findedMsg.total)
      } else {
        messages.push(0)
      }
    })

    let historyWhastapp = response.data.historyWhastapp[0] || null
    let totalWhastapp = historyWhastapp != null ? parseInt(historyWhastapp.finalizado) + parseInt(historyWhastapp.firstcontact) + parseInt(historyWhastapp.menu) + parseInt(historyWhastapp.paticipacion) + parseInt(historyWhastapp.register) : 0
    let bot = []
    bot.push(historyWhastapp != null ? Math.round((historyWhastapp.finalizado * 100) / totalWhastapp) : 0)
    bot.push(historyWhastapp != null ? Math.round((historyWhastapp.firstcontact * 100) / totalWhastapp) : 0)
    bot.push(historyWhastapp != null ? Math.round((historyWhastapp.menu * 100) / totalWhastapp) : 0)
    bot.push(historyWhastapp != null ? Math.round((historyWhastapp.paticipacion * 100) / totalWhastapp) : 0)
    bot.push(historyWhastapp != null ? Math.round((historyWhastapp.register * 100) / totalWhastapp) : 0)
    setBotAnalytics(bot)

    setLabelsDay(labelsDays)
    setWhastappDay({ daysParticipaciones: daysParticipacionesWhats, daysParticipantes: daysParticipantesWhats })
    setWebDay({ daysParticipaciones: daysParticipacionesWeb, daysParticipantes: daysParticipantesWeb })

    setNewSuscribers(suscribers)
    setNewConversations(conversations)
    setNewMessages(messages)

    setProductsDataset({
      activia,
      beng,
      danette,
      danmix,
      danone,
      danonino,
      danup,
      dany,
      oikos,
      vitalinea
    })

    setMetricts(response.data.metricts)
  };

  const statesList = ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Coahuila", "Colima", "Chiapas", "Chihuahua", "Ciudad de México", "Durango", "Estado de México", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "Michoacan", "Morelos", "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"]

  const generarFechasEnRango = (fechaInicio, fechaFin) => {
    const fechas = [];
    let fechaActual = new Date(fechaInicio);

    while (fechaActual <= new Date(fechaFin)) {
      fechas.push(fechaActual.toISOString().split('T')[0]);
      fechaActual.setDate(fechaActual.getDate() + 1);
    }

    return fechas;
  };

  useEffect(() => {
    getMetrictsDetail();
  }, []);

  return metricts
    ?
    <Grid container spacing={2} sx={{ marginTop: 1 }}>
      <Grid item sx={6} md={2}>
        <DatePicker
          sx={{ width: "100%" }}
          label="Desde"
          minDate={dayjs('2024-08-10')}
          maxDate={dateEnd}
          value={dateInit}
          onChange={(value) => { setDateInit(value) }}
        />
      </Grid>
      <Grid item sx={6} md={2}>
        <DatePicker
          sx={{ width: "100%" }}
          label="Hasta"
          minDate={dateInit}
          maxDate={dayjs('20204-10-10')}
          value={dateEnd}
          onChange={(value) => { setDateEnd(value) }}
        />
      </Grid>
      <Grid container item sx={12} md={2} alignItems={'center'}>
        <Button
          variant='contained'
          onClick={() => { getMetrictsDetail() }}
        >Buscar</Button>
      </Grid>
      <Grid item sx={0} md={6}></Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>Métricas de la promoción</Typography>
                </Paper>
              </Grid>

              <Grid container item xs={12} spacing={0}>
                <Grid item xs={6}>
                  <Paper variant='outlined' square sx={{ padding: 1 }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
                    <br />
                    <Typography variant='body1' >Número total de participantes</Typography>
                    <Typography variant='body2'><strong>{metricts.whatsappusers}</strong> de {metricts.totalusers} totales</Typography>

                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper variant='outlined' square sx={{ padding: 1 }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
                    <br />
                    <Typography variant='body1' >Número total de participaciones</Typography>
                    <Typography variant='body2'><strong>{metricts.whatsappbarcodes}</strong> de {metricts.totalbarcodes
                    } totales</Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={0}>
                <Grid item xs={6}>
                  <Paper variant='outlined' square sx={{ padding: 1 }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center' }}><Laptop sx={{ marginRight: 1 }} />Web</Typography>
                    <br />
                    <Typography variant='body1' >Número total de participantes</Typography>
                    <Typography variant='body2'><strong>{metricts.webusers}</strong> de {metricts.totalusers} totales</Typography>

                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper variant='outlined' square sx={{ padding: 1 }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center' }}><Laptop sx={{ marginRight: 1 }} />Web</Typography>
                    <br />
                    <Typography variant='body1' >Número total de participaciones</Typography>
                    <Typography variant='body2'><strong>{metricts.webbarcodes}</strong> de {metricts.totalbarcodes
                    } totales</Typography>
                  </Paper>
                </Grid>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Grid>


      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><Laptop sx={{ marginRight: 1 }} />Web</Typography>
            <BarChart labels={statesList} data={WebState} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
            <BarChart labels={statesList} data={whastappState} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><Laptop sx={{ marginRight: 1 }} />Web</Typography>
            <BarLineChart labels={labelsDay} data={WebDay} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
            <BarLineChart labels={labelsDay} data={whastappDay} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><Laptop sx={{ marginRight: 1 }} />Web</Typography>
            <BarParticipacionChart labels={labelsDay} data={WebDay} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
            <BarParticipacionChart labels={labelsDay} data={whastappDay} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <StackBarChart labels={labelsDay} data={productsDataset} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <PolarChart data={botAnalytics} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
            <BartLineChartGeneric labels={labelsDay} data={newSuscribers} title={'Nuevos suscriptores'} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
            <BartLineChartGeneric labels={labelsDay} data={newConversations} title={'Nuevas conversaciones'} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant={'subtitle1'} fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}><WhatsApp sx={{ marginRight: 1 }} />Whatsapp</Typography>
            <BartLineChartGeneric labels={labelsDay} data={newMessages} title={'Mensajes enviados'} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    :
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>No se encontró información</Typography>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
};


export { Dashboard };