import React from 'react';
import './FullScreenLayout.scss';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { Modal } from '../../components/Modal';
import { Loader } from '../../components/Loader';

function FullScreenLayout({ children }) {
  return (
    <ErrorBoundary>
      <Modal />
      <Loader />
      <div className="full-screen-layout">{children}</div>
    </ErrorBoundary>
  );
}

export { FullScreenLayout };
