import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 500
  },
  errorCode: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    'span:last-of-type': {
      marginLeft: theme.spacing(1)
    }
  }
}));

export { useStyles };
