import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi, useAuth } from "../../hooks";
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
  const navigate = useNavigate();
  const { actions: authActions } = useAuth();
  const [password, setPassword] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  const requiredFieldError = "Campo requerido";

  const getValidationRules = () =>
    Yup.object().shape({
      email: Yup.string().required(requiredFieldError),
      password: Yup.string().required(requiredFieldError),
    });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules,
    onSubmit: async values => {
      let body = { email: values.email, password: values.password };
      await handleSubmit(body)
    },
  });

  const [loginService] = useApi({
    endpoint: '/auth/dash/login',
    method: 'post'
  });

  const handleSubmit = async (data) => {
    try {
      const authResponse = await loginService({
        body: data
      });
      const dataResponse = get(authResponse, ['data'], null);

      const token = get(dataResponse, 'token', null);
      const email = get(dataResponse, 'email', null);
      const user = get(dataResponse, 'name', null);
      const id = get(dataResponse, 'id', null);

      let profile = {
        user: user,
        role: "admin"
      }

      if (token) {
        const sessionData = {
          token,
          user: profile,
          email,
          id,
          status: true
        };

        await authActions.login(sessionData);

        window.location.href = '/'
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return { formik, password, setPassword }
}