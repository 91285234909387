import React, { useEffect, useState } from 'react';
import { Alert, Card, CardContent, Grid, Paper, TextField, Typography, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useApi } from '../../hooks';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useModal } from '../Modal';
import { Photo, OpenInNew } from '@mui/icons-material';

const ParticipacionesDetail = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const { handleOpenModal, handleCloseModal } = useModal();

  const [participacionData, setParticipacionData] = useState({});
  const [select, setSelect] = useState('');

  const [getParticipacion] = useApi({
    endpoint: 'barcode/detail',
    method: 'get'
  });

  const getParticipacionDetail = async () => {
    const response = await getParticipacion({ urlParams: id });
    setParticipacionData(response.data)
    setSelect(response.data.status)
  };

  const [updateParticipacion] = useApi({
    endpoint: 'barcode/update/state',
    method: 'post'
  });

  const updateStateParticipacion = async (id, status) => {
    let body = {
      id,
      status
    }
    const response = await updateParticipacion({ body: body });

    navigate("/participaciones/" + participacionData.idparticipacion)
  };

  useEffect(() => {
    getParticipacionDetail();
  }, []);


  const viewImageUser = () => {
    handleOpenModal({
      body: (
        <Paper variant='elevation' sx={{ width: '100%', maxHeight: '90vh' }}>
          <img
            style={{ width: '100%', maxHeight: '80vh' }}
            src={participacionData.urlImage}
            alt="image-participacion"
          />
          <Button variant='contained' fullWidth onClick={() => { handleCloseModal() }}>Cerrar</Button>
        </Paper>
      )
    })
  }
  return participacionData
    ?
    <Grid container spacing={2} >
      <Grid item xs={12} md={6}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>Información del participante</Typography>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Nombre'}
                  value={participacionData.name} />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Estado'}
                  value={participacionData.state} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Teléfono'}
                  value={participacionData.phone} />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Correo electrónico'}
                  value={participacionData.email} />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} direction={'row'}>
                  <Grid item xs={12} md={4}>
                    <Alert variant={'filled'} severity="warning">
                      {participacionData.pendientes} &nbsp; Códigos registrados pendientes
                    </Alert>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Alert variant={'filled'} severity="success">
                      {participacionData.aceptados} &nbsp; Códigos registrados aceptados
                    </Alert>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Alert variant={'filled'} severity="error">
                      {participacionData.rechazados} &nbsp; Códigos registrados rechazados
                    </Alert>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button variant='contained' fullWidth onClick={() => { navigate('/participantes/' + participacionData.idparticipante) }}>Ver participante</Button>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>Participación</Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>Puntos obtenidos:{participacionData.points}</Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Button variant='text' fullWidth startIcon={<Photo />} onClick={() => { viewImageUser() }}>
                    Ver imagen
                  </Button>
                  <Button variant='text' fullWidth startIcon={<OpenInNew />} onClick={() => { participacionData.urlImage && window.open(participacionData.urlImage, '_blank') }}>
                    Abrir imagen
                  </Button>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Producto'}
                  value={participacionData.product} />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Código de barras'}
                  value={participacionData.barcode} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Lote'}
                  value={participacionData.lote} />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Origen'}
                  value={participacionData.channel} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Fecha de participación'}
                  value={moment(participacionData.createdDate).format('DD/MM/YYYY HH:mm:SS')} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Categoría'}
                  value={participacionData.type} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Tipo Premio'}
                  value={participacionData.instantaneo ? "Instantaneo" : "Diario"} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Premio'}
                  value={participacionData.instantaneo ? participacionData.instantaneo : participacionData.diario} />
              </Grid>

              <Grid item xs={12}>
                {participacionData.status == "Pendiente"
                  ?
                  <FormControl fullWidth>
                    <InputLabel id="select-label-status">Estado</InputLabel>
                    <Select
                      labelId="select-label-status"
                      value={select}
                      label="Estado"
                      onChange={(e) => {
                        setSelect(e.target.value)
                      }}
                    >
                      <MenuItem value={"Pendiente"} >Pendiente</MenuItem>
                      <MenuItem value={"Aceptado"} >Aceptado</MenuItem>
                      <MenuItem value={"Rechazado"}>Rechazado</MenuItem>
                    </Select>
                  </FormControl>
                  :
                  <TextField
                    fullWidth
                    variant='outlined'
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={'Estado'}
                    value={participacionData.status} />
                }
              </Grid>
              {participacionData.status == "Pendiente" &&
                <Grid item xs={12}>
                  <Button variant='contained' fullWidth onClick={() => { updateStateParticipacion(participacionData.idparticipacion, select) }}>Guardar información</Button>
                </Grid>}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
    :
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>No se encontró información</Typography>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
};


export { ParticipacionesDetail };