export const config = {
  appId: "50danone-dashboard",
  siteConfig: {
    defaultTheme: 'light',
    allowBrowserTheme: false
  },
  endpoints: {
    mainBackendUrl: 'https://api-landing-9838hd.mywebserver.in/api/v1/'
  },
};
