import {
  Checkbox,
  TableRow,
  TableCell,
  TableHead as Head,
  TableSortLabel,
  Typography
} from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';

import { useStyles } from './TableHeadStyles';
import { size } from 'lodash';


function TableHead(props) {
  const {
    order,
    orderBy,
    rowCount,
    headLabel,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    tableHeadStyles,
    checkboxHidden
  } = props;
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <Head className={`${tableHeadStyles}`}>
      <TableRow>
        {!checkboxHidden && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              color="primary"
            />
          </TableCell>
        )}
        {size(headLabel) > 0 &&
          headLabel.map(({ key, align, label }) => (
            <TableCell
              key={key}
              align={align ? align : 'left'}
              sortDirection={orderBy === key ? order : false}
            >
              <TableSortLabel
                active={orderBy === key}
                direction={orderBy === key ? order : 'asc'}
                onClick={createSortHandler(key)}
              >
                {align === 'center' && (
                  <ArrowDownward className={classes.arrowHidden} />
                )}
                {label}
                {orderBy === key ? (
                  <Typography
                    variant="body1"
                    className={classes.visuallyHidden}
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Typography>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </Head>
  );
}

export { TableHead };
