import { makeStyles } from '@mui/styles';
import { hexToRgba } from '../../../providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3)
  },

  checkboxHidden: {
    padding: theme.spacing(0, 1)
  },

  bgPrimary: {
    color: theme.palette.primary.main,
    backgroundColor: hexToRgba(theme.palette.primary.main, 0.25),
    height: 96,
    minHeight: 96
  },

  shortHeader: {
    height: 10,
    minHeight: 10
  },

  selectedTitle: {
    fontWeight: 600,
    opacity: 1
  },

  filterProperty: {
    borderRadius: '8px 0 0 8px',
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${hexToRgba(theme.palette.grey[600], 0.32)} !important`
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiOutlinedInput-input': {
        padding: '10px 14px'
      }
    },
    [theme.breakpoints.down('401')]: {
      maxWidth: 80
    }
  },

  searchStyle: {
    width: 240,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
      width: 320,
      boxShadow: theme.customShadows.z8
    },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${hexToRgba(theme.palette.grey[600], 0.32)} !important`,
      borderLeftWidth: '0 !important'
    },
    [theme.breakpoints.down('xs')]: {
      '&.Mui-focused': {
        width: 240
      },
      '& .MuiOutlinedInput-input': {
        padding: 10
      }
    },
    [theme.breakpoints.down('401')]: {
      width: 130,
      '&.Mui-focused': {
        width: 130
      }
    }
  },

  searchIcon: {
    color: theme.palette.text.disabled
  }
}));

export { useStyles };
