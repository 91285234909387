import { get, map, filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import { routes as _routes } from './routes';
import { useAuth } from '../hooks';
import { Helmet } from 'react-helmet';
import { metas } from './metas';
import './Navigation.scss';

import { Error404 } from '../views/Error404';
function Navigation() {
  return (
    <Router>
      <AnimatedRouter />
    </Router>
  );
}

function AnimatedRouter() {
  const { auth } = useAuth();
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState('fadeIn');

  // useEffect(() => {
  //   if (!auth) {
  //     setTransistionStage('fadeIn');
  //     setDisplayLocation(location);
  //   } else if (location !== displayLocation) setTransistionStage('fadeOut');
  // }, [location, auth]);

  const AnimationWrapper = ({ children }) => (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === 'fadeOut') {
          setTransistionStage('fadeIn');
          setDisplayLocation(location);
        }
      }}
    >
      {children}
    </div>
  );

  const role = get(auth, 'role', 'public');

  let routes = _routes[role];

  return (
    <Routes>
      {role !== 'public' &&
        map(
          ['/login'],
          (path, index) => (
            <Route
              key={`public-route-${index}`}
              path={path}
              exact={true}
              element={<Navigate to="/" />}
            />
          )
        )}
      {map(routes, (route, index) => (
        <Route
          key={`${role}-route-${index}`}
          path={route.path}
          exact={true}
          element={
            <>
              <Helmet>
                <title>{route.titleMessage}</title>
                {get(metas, route.path, []).map((meta, index) => (
                  <meta key={`meta-${index}`} {...meta} />
                ))}
              </Helmet>
              <route.layout>
                <AnimationWrapper>
                  <route.component />
                </AnimationWrapper>
              </route.layout>
            </>
          }
        />
      ))}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export { Navigation };
