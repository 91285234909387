import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { Table } from '../Table';
import { useApi } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const Participantes = (props) => {
  const navigate = useNavigate();
  const [participantes, setParticipantes] = useState([]);

  const [getParticipantes] = useApi({
    endpoint: 'auth/users/list',
    method: 'get'
  });


  const getParticipantesList = async () => {
    const response = await getParticipantes();

    const updatedData = response.data.map((item) => ({
      ...item,
      name: item.name == null ? "" : item.name,
    }));

    setParticipantes(updatedData);
  };

  useEffect(() => {
    getParticipantesList();
  }, []);


  const tableHead = [
    { key: 'name', label: "Nombre" },
    { key: 'phone', label: "Teléfono" },
    {
      key: 'email',
      label: "Correo electrónico"
    },
    { key: 'state', label: "Estado" },
    { key: '', label: '' }
  ];

  const filters = [
    {
      key: 'name',
      value: "Nombre"
    }
  ];

  const configProps = {
    filters,
    actions: {
      customs: [{
        text: "Ver",
        icon: Visibility,
        onClick: (item) => { navigate(`/participantes/${item.id}`) }
      }]
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div>
      <Table data={participantes} columns={tableHead} configProps={configProps} />
    </div>
  );
};


export { Participantes };