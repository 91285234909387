import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Table } from '../Table';
import { useApi } from '../../hooks';
import { Visibility, Download } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { config } from '../../providers/config';

const Ranking = (props) => {
  const navigate = useNavigate();
  const [ranking, setRanking] = useState([]);
  const [dateInit, setDateInit] = useState(dayjs('2024-08-10'));
  const [dateEnd, setDateEnd] = useState(dayjs().add(1, 'days'));
  const [getRanking] = useApi({
    endpoint: 'barcode/ranking',
    method: 'get'
  });


  const getRankingList = async () => {
    const startDate = dateInit.toDate()
    startDate.setHours(0, 0, 0, 0);

    const endDate = dateEnd.toDate();
    endDate.setHours(23, 59, 59, 999);


    const response = await getRanking({
      queryString: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
    });
    setRanking(response.data)
  };

  const getRankingListDownload = async () => {
    const startDate = dateInit.toDate()
    startDate.setHours(0, 0, 0, 0);

    const endDate = dateEnd.toDate();
    endDate.setHours(23, 59, 59, 999);

    const queryString = new URLSearchParams({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    }).toString();


    try {
      const response = await fetch(config.endpoints.mainBackendUrl + `/barcode/ranking/download?${queryString}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ranking.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Error al descargar el archivo:', response.statusText);
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };

  useEffect(() => {
    getRankingList();
  }, []);


  const tableHead = [
    { key: 'participant', label: "Participante" },
    { key: 'phone', label: "Teléfono" },
    {
      key: 'email',
      label: "Correo electrónico"
    },
    { key: 'state', label: "Estado" },
    { key: 'totalpoints', label: "Puntos" },
    { key: '', label: '' }
  ];

  const filters = [
    {
      key: 'participant',
      value: "Participante",
    },
    { key: 'state', value: "Estado" },
  ];

  const configProps = {
    filters,
    actions: {
      customs: [{
        text: "Ver",
        icon: Visibility,
        onClick: (item) => { navigate(`/participantes/${item.id}`) }
      }]
    },
    selectedOptions: {
      checkboxHidden: true
    },
    orderbyProp: 'totalpoints',
    orderProp: 'desc'
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item sx={6} md={2}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Desde"
            minDate={dayjs('2024-08-10')}
            maxDate={dateEnd}
            value={dateInit}
            onChange={(value) => { setDateInit(value) }}
          />
        </Grid>
        <Grid item sx={6} md={2}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Hasta"
            minDate={dateInit}
            maxDate={dayjs('20204-10-10')}
            value={dateEnd}
            onChange={(value) => { setDateEnd(value) }}
          />
        </Grid>
        <Grid container item sx={6} md={2} alignItems={'center'}>
          <Button
            variant='contained'
            onClick={() => { getRankingList() }}
          >Buscar</Button>
        </Grid>
        <Grid item sx={0} md={4}></Grid>
        <Grid item sx={12} md={2}>
          <Button
            variant='contained'
            startIcon={<Download />}
            onClick={() => { getRankingListDownload() }}
          >
            Descargar
          </Button>
        </Grid>
      </Grid>
      <Table data={ranking} columns={tableHead} configProps={configProps} />
    </>
  );
};


export { Ranking };