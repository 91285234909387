import React, { useRef, useState } from 'react';
import { get, size } from 'lodash';
// material
import { MoreVert, Edit, DeleteOutline } from '@mui/icons-material';
import { Menu, IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
// ----------------------------------------------------------------------
import { useStyles } from './TableOptionsStyles';
import { Option } from './Option';
import { ConfirmationDelete } from '../../ConfirmationDelete';
import { useModal } from '../../Modal';

function TableOptions(props) {
  const { actions, item, dots = {} } = props;
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { handleOpenModal } = useModal();
  const customs = get(actions, 'customs', {});
  const hasEdition = size(get(actions, 'edit', {})) > 0;
  const hasElimination = size(get(actions, 'deleteAction', {})) > 0;

  const openConfirmationModal = (item, callback, rest) => {
    handleOpenModal({
      title: '¿Estás seguro de eliminar este registro?',
      body: 'Si borras el registro no podrás recuperarlo',
      actionButtons: <ConfirmationDelete handleDelete={() => callback(item)} />,
      ...rest
    });
  };

  const handleClick = (callback, item, deleteAction = false, modalConfig) => {
    setIsOpen(false);
    if (deleteAction) {
      return openConfirmationModal(item, callback, modalConfig);
    }

    if (callback) return callback(item);
  };

  const renderEditOption = (item, other) => {
    const { text, onClick, hidden, disabled, hiddenIcon } = other;

    if (hidden) return null;

    return (
      <MenuItem
        className={classes.item}
        disabled={disabled}
        onClick={() => handleClick(onClick, item)}
      >
        {!hiddenIcon && (
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
        )}
        <ListItemText
          primary={get(text, 'text', 'Editar')}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
    );
  };

  const renderDeleteOption = (item, other) => {
    const { text, onClick, hidden, disabled, hiddenIcon, ...rest } = other;

    if (hidden) return null;

    return (
      <MenuItem
        className={classes.item}
        disabled={disabled}
        onClick={() => handleClick(onClick, item, true, rest)}
      >
        {!hiddenIcon && (
          <ListItemIcon>
            <DeleteOutline />
          </ListItemIcon>
        )}
        <ListItemText
          primary={get(text, 'text', 'Eliminar')}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </MenuItem>
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVert {...dots} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: classes.menu
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {hasEdition > 0 && renderEditOption(item, { ...actions.edit })}
        {hasElimination > 0 &&
          renderDeleteOption(item, { ...actions.deleteAction })}

        {Array.isArray(customs)
          ? customs.map((custom, index) => (
            <Option
              item={item}
              key={index}
              {...custom}
              setIsOpen={setIsOpen}
            />
          ))
          : customs &&
          size(customs) > 0 && (
            <Option item={item} {...customs} setIsOpen={setIsOpen} />
          )}
      </Menu>
    </>
  );
}

export { TableOptions };
