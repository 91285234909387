import React from 'react';
import { Header } from '../../components/Header';
import { Loader } from '../../components/Loader';
import { BasicLoader } from '../../components/BasicLoader';
import { Modal } from '../../components/Modal';
import './HomeBaseLayout.scss';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { useStyles } from './HomeBaseLayoutStyles';
import { SideMenu, useSideMenu } from '../../components/SideMenu';
import { useMediaQuery, useTheme } from '@mui/material';
import { Drawer } from '../../components/Drawer';

function HomeBaseLayout({ children }) {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { mini } = useSideMenu();

  const classes = useStyles({ mini });

  return (
    <ErrorBoundary>
      <Loader>
        <BasicLoader />
      </Loader>
      <Modal />
      <Header />
      <div className={`base-layout ${classes.container}`}>
        <SideMenu />
        <div className={classes.appBarSpacer} />
        <div
          style={{
            [theme.direction === 'ltr' ? 'marginLeft' : 'marginRight']: mdUp
              ? theme.mixins.getSideMenu(mini).width
              : 0,
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.standard
            })
          }}
        >
          {children}
        </div>
        <Drawer />
      </div>
    </ErrorBoundary>
  );
}

export { HomeBaseLayout };
