export const metas = {
  '/': [
    {
      name: 'description',
      content: 'Home component'
    }
  ],
  '/dashboard': [
    {
      name: 'description',
      content: 'Dasboard component'
    }
  ],
  '/Participaciones': [
    {
      name: 'description',
      content: 'Participaciones component'
    }
  ],
  '/Ranking': [
    {
      name: 'description',
      content: 'Tags component'
    }
  ],
  '/Participantes': [
    {
      name: 'description',
      content: 'Tasks component'
    }
  ],
  '/Premios': [
    {
      name: 'description',
      content: 'Users component'
    }
  ],
  '/Usuarios': [
    {
      name: 'description',
      content: 'Search component'
    }
  ]
};
