import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import { Typography, Button } from '@mui/material';
import { useStyles } from './Error404Styles';
import { useAuth } from '../../hooks';
import lottieJson from '../../assets/animations/404.json';

function Error404() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const role = get(auth, 'role', 'public');


  if (role === 'public') {
    return <Navigate to="/login" />;
  }

  return (
    <div className={classes.container}>
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{
          height: '85%'
        }}
      />

      <div className={classes.errorDetails}>
        <Typography color="primary" className="title">
          Página no encontrada
        </Typography>
        <Typography variant="body1" className="description">
          La página no existe o el contenido ya no está disponible
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate('/')}
        >
          Ir a la página de inicio
        </Button>
      </div>
    </div>
  );
}

export { Error404 };
