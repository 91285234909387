import React from 'react';
import { AuthProvider } from './components/Auth';
import { ModalProvider } from './components/Modal';
import { LoaderProvider } from './components/Loader';
import { App as AppMain } from './components/App';
import { ThemeProvider } from './components/Theme';
import { DrawerProvider } from './components/Drawer';
import { SideMenuProvider } from './components/SideMenu';
import { config } from './providers/config';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';

export const Root = () => {
  return (
    <>
      <ThemeProvider defaultTheme={config.siteConfig.defaultTheme}>
        <LoaderProvider>
          <ModalProvider>
            <DrawerProvider>
              <AuthProvider>
                <SideMenuProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es"> 
                    <AppMain />
                  </LocalizationProvider>
                </SideMenuProvider>
              </AuthProvider>
            </DrawerProvider>
          </ModalProvider>
        </LoaderProvider>
      </ThemeProvider>
    </>
  );
}
