import React, { useState, useMemo } from 'react';
import { setToken, getToken, removeToken } from '../../utils/token';
import { AuthContext } from './AuthContext';

const AuthProvider = (props) => {
  const { children } = props;

  const token = getToken();
  const session = window.localStorage.getItem('session');
  const [auth, setAuth] = useState(!!token ? JSON.parse(session) : null);

  const login = ({ token, user }) => {
    setToken(token);
    window.localStorage.setItem('session', JSON.stringify(user));
    setAuth({ user });
  };

  const logout = () => {
    removeToken();
    setAuth(null);
    window.localStorage.removeItem('session');
    window.location.href = "/login";
  };

  const contextValue = useMemo(
    () => ({
      auth,
      actions: {
        login,
        logout
      }
    }),
    [auth]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider };
