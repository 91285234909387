import React from 'react';
import { Box, Button } from '@mui/material';
import { useModal } from '../Modal/hooks/useModal';
import { useStyles } from './ConfirmationDeleteStyles';

const ConfirmationDelete = (props) => {
  const { handleDelete } = props;
  const classes = useStyles();
  const { handleCloseModal } = useModal();

  return (
    <Box className={classes.container} justifyContent="flex-end">
      <Button variant="outlined" color="default" onClick={handleCloseModal}>
        Cancelar
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDelete}
        className={classes.margin}
      >
        Eliminar
      </Button>
    </Box>
  );
};


export { ConfirmationDelete };
