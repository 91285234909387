// material
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './TableTopStyles';

const TableTop = (props) => {
  const { title, handleAdd, addTitle } = props;
  const classes = useStyles();

  return (
    <Box className={classes.heading} mb={5}>
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}
      {handleAdd && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          className={`${!title && classes.rightPosition}`}
          onClick={handleAdd}
        >
          {addTitle}
        </Button>
      )}
    </Box>
  );
};

export { TableTop };
