import React, { useEffect, useState } from 'react';
import { Alert, Card, CardContent, Grid, Paper, TextField, Typography } from '@mui/material';
import { Table } from '../Table';
import { useApi } from '../../hooks';
import { useParams } from 'react-router-dom';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const ParticipanteDetail = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [participanteData, setParticipanteData] = useState({});

  const [getParticipante] = useApi({
    endpoint: 'auth/users',
    method: 'get'
  });


  const getParticipanteDetail = async () => {
    const response = await getParticipante({ urlParams: id });

    let aux = response.data.participaciones.slice()
    aux.map(
      (item) =>
        (item.createdDate = moment(item.createdDate).format('DD/MM/YYYY HH:mm:SS'))
    );

    response.data.participaciones = aux
    setParticipanteData(response.data)
  };

  useEffect(() => {
    getParticipanteDetail();
  }, []);


  const tableHead = [
    { key: 'product', label: "Producto" },
    { key: 'code', label: "Código de barras" },
    {
      key: 'lote',
      label: "Lote"
    },
    { key: 'createdDate', label: "Fecha y hora" },
    { key: '', label: '' }
  ];

  const filters = [
    {
      key: 'product',
      value: "Producto"
    }
  ];

  const configProps = {
    filters,
    actions: {
      customs: [{
        text: "Ver",
        icon: Visibility,
        onClick: (item) => { navigate(`/participaciones/${item.id}`) }
      }]
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return participanteData
    ?
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>Información del participante</Typography>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Nombre'}
                  value={participanteData.name} />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Estado'}
                  value={participanteData.state} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Teléfono'}
                  value={participanteData.phone} />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant='outlined'
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Correo electrónico'}
                  value={participanteData.email} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12} md={4}>
                <Alert variant={'filled'} severity="warning">
                  {participanteData.pendientes} &nbsp; Códigos registrados pendientes
                </Alert>
              </Grid>

              <Grid item xs={12} md={4}>
                <Alert variant={'filled'} severity="success">
                  {participanteData.aceptados} &nbsp; Códigos registrados aceptados
                </Alert>
              </Grid>

              <Grid item xs={12} md={4}>
                <Alert variant={'filled'} severity="error">
                  {participanteData.rechazados} &nbsp; Códigos registrados rechazados
                </Alert>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>


      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table data={participanteData.participaciones} columns={tableHead} configProps={configProps} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
    :
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>No se encontró información</Typography>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
};


export { ParticipanteDetail };