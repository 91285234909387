import React, { useState, useEffect } from 'react';
import _, { get } from 'lodash';

import {
  Card,
  Table as MUITable,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination
} from '@mui/material';

import { SearchNotFound } from './SearchNotFound';
import { TableHead } from './TableHead';
import { TableToolbar } from './TableToolbar';
import { TableOptions } from './TableOptions';
import { TableTop } from './TableTop';
import { useStyles } from './TableStyles';

import { getComparator, applySortFilter } from '../../utils/table';

function Table(props) {
  const {
    mainKey = '_id',
    onSelectItems,
    data = [],
    columns = [],
    configProps = {},
    searchValue
  } = props;
  const {
    filters = [],
    topTable = {},
    actions: _actions,
    dots,
    tableStyles = {},
    selectedOptions = {
      checkboxHidden: false,
      selectedIcon: null,
      selectedTooltip: null
    },
    filterProp = null,
    orderbyProp = null,
    orderProp = 'asc'
  } = configProps;
  const {
    tableContainer = '',
    tableHeadStyles = '',
    tableToolbarStyles = '',
    tableRowStyles = ''
  } = tableStyles;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(orderProp);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(orderbyProp || get(columns, [0, 'key']));
  const [filterProperty, setFilterProperty] = useState(
    get(filters, [0, 'key']) || filterProp
  );
  const [filteredValue, setFilteredValue] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredData.map((n) => n[mainKey]);

      setSelected(newSelecteds);

      return;
    }

    setSelected([]);
  };

  const checkItem = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setFilteredValue(event.target.value);
  };

  useEffect(() => {
    setFilteredValue(searchValue);

    return () => setFilteredValue('');
  }, [searchValue]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filteredValue,
    filterProperty
  );

  const isDataNotFound = filteredData.length === 0;

  const onChangeFilterBy = (e) => {
    const {
      target: { value }
    } = e;

    setFilterProperty(value);
  };

  const renderCell = (row) => {
    const items = [];
    let columnCopy = [...columns];

    columnCopy.forEach((column) => {
      for (const [key, value] of Object.entries(row)) {
        if (key === column.key) {
          items.push(
            <TableCell
              key={`${row[mainKey]}-${key}`}
              align={column.align ? column.align : 'left'}
            >
              {value}
            </TableCell>
          );
        }
      }
    });
    columnCopy.pop();

    return items;
  };

  return (
    <Container>
      <TableTop {...topTable} />

      <Card className={`${classes.tableCardContainer} ${tableContainer}`} variant='outlined'>
        <TableToolbar
          filters={filters}
          numSelected={selected.length}
          filteredValue={filteredValue}
          onFilter={handleFilter}
          filterProperty={filterProperty}
          onChangeFilterBy={onChangeFilterBy}
          onSelectItems={() => onSelectItems && onSelectItems(selected)}
          selectedOptions={selectedOptions}
          tableToolbarStyles={tableToolbarStyles}
        />

        <TableContainer>
          <MUITable>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={columns}
              rowCount={data.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              tableHeadStyles={tableHeadStyles}
              checkboxHidden={selectedOptions.checkboxHidden}
            />
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const id = row[mainKey];
                  const isItemSelected = selected.indexOf(id) !== -1;
                  const actions = get(_actions, 'isFunction') ? _actions(row) : _actions;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      classes={{ selected: classes.selected }}
                      className={`${tableRowStyles}`}
                    >
                      {!selectedOptions.checkboxHidden && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => checkItem(event, id)}
                            color="primary"
                          />
                        </TableCell>
                      )}
                      {renderCell(row)}
                      {!get(actions, 'hidden', false) && (
                        <TableCell align="center">
                          <TableOptions
                            actions={actions}
                            item={row}
                            dots={dots}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isDataNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    className={classes.cellNotFound}
                  >
                    <SearchNotFound searchQuery={filteredValue} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </MUITable>
        </TableContainer>

        <TablePagination
          labelRowsPerPage="Registros por página"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}

export { Table };
