import { PolarArea } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  PolarAreaController,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registrar los componentes que vas a usar
ChartJS.register(
  CategoryScale,
  PolarAreaController,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend
);


const PolarChart = ({ labels, data }) => {
  const dataBar = {
    labels: [
      'Primer contacto',
      'Registro participante',
      'Menú',
      'Registro de participación',
      'Finalizado'
    ],
    datasets: [
      {
        label: 'Primer contacto',
        borderColor: ['rgba(102, 176, 211, .5)', 'rgba(103, 142, 200, .5)', 'rgba(100, 108, 179, .5)', 'rgba(115, 100, 173, .5)', 'rgba(140, 102, 172, .5)'],
        backgroundColor: ['rgba(102, 176, 211, .5)', 'rgba(103, 142, 200, .5)', 'rgba(100, 108, 179, .5)', 'rgba(115, 100, 173, .5)', 'rgba(140, 102, 172, .5)'],
        data: data
      },

    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Análisis Bot',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.formattedValue + '%';
          }
        }
      }
    }
  };

  return <PolarArea data={dataBar} options={options} style={{ maxHeight: 400 }} />;
};


export { PolarChart };