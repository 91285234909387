import _ from 'lodash';
import React from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useStyles } from './HeaderStyles';
import { Menu } from '@mui/icons-material';
import { useSideMenu } from '../../components/SideMenu';
import { Images } from '../../assets';

const Header = () => {
  const { setVisible, mini } = useSideMenu();
  const classes = useStyles({ mini });
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      <AppBar color="primary" position="fixed">
        <Toolbar
          style={{
            [theme.direction === 'ltr' ? 'marginLeft' : 'marginRight']: mdUp
              ? theme.mixins.getSideMenu(mini).width
              : 0,
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.standard
            })
          }}
        >
          <Tooltip title={"Abrir menú"} sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="switch-sidemenu"
              onClick={() => setVisible((visible) => !visible)}
            >
              <Menu />
            </IconButton>
          </Tooltip>

          <Box sx={{ position: 'absolute', right: 10 }}>
            <img
              src={Images.headerLogo}
              alt="headerLogo"
              style={{ margin: 3 }}
            />
          </Box>

        </Toolbar>
      </AppBar>
    </div>
  );
};

export { Header };
