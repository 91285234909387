import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  arrowHidden: {
    color: 'transparent',
    fontSize: 18,
    marginRight: theme.spacing(0.5)
  }
}));

export { useStyles };
