import React from 'react';
import { get, toUpper } from 'lodash';
import { Avatar, Box, ButtonBase, Typography } from '@mui/material';
import { useStyles } from './ProfilePreviewStyles';
import { useSideMenu } from '..';
import { useAuth } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

const ProfilePreview = () => {
  const navigate = useNavigate();
  const { expanded } = useSideMenu();
  const classes = useStyles({ expanded });
  const { auth } = useAuth();
  const account = { name: get(auth, 'user', '') };
  const firstLetter = toUpper(get(account, ['name', 0]));

  const user = {
    name: get(account, 'name', ''),
  };

  return (
    <ButtonBase
      component={Box}
      className={classes.container}
      style={{ display: "flex", justifyContent: 'center', marginTop: 10 }}
      onClick={() => { navigate('/usuarios') }}
    >
      <Avatar
        className={classes.avatar}
      >
        {firstLetter}
      </Avatar>
      <Box pl={1.5} className={classes.textContainer}>
        <Typography variant="subtitle2">{user.name}</Typography>
      </Box>
    </ButtonBase>
  );
};

export { ProfilePreview };
