import { jwtDecode } from 'jwt-decode';

const TOKEN = 'token';

export const setToken = (token) => {
  window.localStorage.setItem(TOKEN, token);
};

export const getToken = () => window.localStorage.getItem(TOKEN);

export const decodeToken = (token) => jwtDecode(token);

export const removeToken = () => window.localStorage.removeItem(TOKEN);
