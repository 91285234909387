import React from 'react';

import { Delete, Search } from '@mui/icons-material';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import { useStyles } from './TableToolbarStyles';


function TableToolbar(props) {
  const {
    numSelected,
    filteredValue,
    onFilter,
    onChangeFilterBy,
    filterProperty,
    filters = [],
    onSelectItems,
    tableToolbarStyles,
    selectedOptions
  } = props;
  const { checkboxHidden, selectedIcon, selectedTooltip } = selectedOptions;
  const classes = useStyles();
  const showFilters = filterProperty && filters.length > 0;

  return (
    <Toolbar
      className={`
        ${classes.root} 
        ${numSelected > 0 && classes.bgPrimary} 
        ${!showFilters && numSelected < 1 && classes.shortHeader}
        ${tableToolbarStyles}
        ${checkboxHidden && classes.checkboxHidden}
      `}
    >
      {numSelected > 0 ? (
        <Typography variant="subtitle1" className={classes.selectedTitle}>
          {numSelected} {' seleccionados'}
        </Typography>
      ) : (
        <Box>
          {showFilters && (
            <>
              <FormControl>
                <Select
                  id="filterProperty"
                  value={filterProperty}
                  onChange={onChangeFilterBy}
                  input={<OutlinedInput />}
                  className={classes.filterProperty}
                >
                  <MenuItem key="option-disabled-1" value="" disabled>
                    Filtrar por
                  </MenuItem>
                  {filters.map(({ key, value }, index) => (
                    <MenuItem key={index} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <OutlinedInput
                className={classes.searchStyle}
                value={filteredValue}
                onChange={onFilter}
                placeholder={'Buscar...'}
                startAdornment={
                  <InputAdornment position="start">
                    <Search
                      className={classes.searchIcon}
                      fontSize="medium"
                    />
                  </InputAdornment>
                }
              />
            </>
          )}
        </Box>
      )}

      {numSelected > 0 && (
        <Tooltip
          title={`${selectedTooltip ? selectedTooltip : 'Eliminar'
            }`}
        >
          <IconButton onClick={onSelectItems}>
            {selectedIcon ? selectedIcon : <Delete />}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export { TableToolbar };
