import defaultLoader from './svg/default-loader.svg';
import companyLogo from './svg/company-logo.svg';
import headerLogo from './svg/logo_danone.svg';

const Images = {
  defaultLoader,
  companyLogo,
  headerLogo
};

export { Images };
