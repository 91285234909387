import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import './Error.scss';

function Error({ clearError }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToHome = () => {
    if (location.pathname === '/') {
      clearError();
    }

    navigate('/');
  };

  return (
    <Container className="container-not-found" maxWidth="xl">
      <div>
        <Typography variant="h1">Error</Typography>
        <Typography variant="h5" color="secondary">
          Ha ocurrido un error inesperado
        </Typography>
        <Typography component="p" variant="subtitle2">
          Lo lamentamos, estaremos trabajando en resolver este error inesperado.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNavigateToHome}
        >
          Ir a la página de inicio
        </Button>
      </div>
    </Container>
  );
}

export { Error };
