import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '1px',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(1),
      height: 'auto'
    }
  },
  backgroundImage: {
    backgroundColor: '#EAEAEA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    margin: 'auto',
    maxWidth: theme.typography.pxToRem(340),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4)
  },
  instructions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '2.225rem'
  },
  submitButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
export { useStyles };