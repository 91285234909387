import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registrar los componentes que vas a usar
ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend
);


const BartLineChartGeneric = ({ labels, data, title }) => {
  const dataBar = {
    labels: labels,
    datasets: [
      {
        label: '',
        borderColor: 'rgba(46, 128, 153, 1)',
        backgroundColor: 'rgba(46, 128, 153, .5)',
        data: data,
        fill: true,
        type: 'line'
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  return <Bar data={dataBar} options={options} style={{ maxHeight: 400 }} />;
};


export { BartLineChartGeneric };