import {
  BaseLayout,
  FullScreenLayout,
  HomeBaseLayout
} from '../layouts';
import { Login } from '../components/Login';
import { Error404 } from '../views/Error404';
import { Dashboard } from '../components/Dashboard';
import { Participaciones } from '../components/Participaciones';
import { ParticipacionesDetail } from '../components/ParticipacionesDetail';
import { Participantes } from '../components/Participantes';
import { ParticipanteDetail } from '../components/ParticipanteDetail';
import { Ranking } from '../components/Ranking';
import { Usuarios } from '../components/Usuarios';
import { UsuarioDetail } from '../components/UsuarioDetail';

export const routes = {
  admin: [
    {
      path: '/',
      layout: HomeBaseLayout,
      component: Dashboard,
      exact: true,
      titleMessage: 'Dashboard',
      private: true,
      isAdmin: true
    },
    {
      path: '/ranking',
      layout: HomeBaseLayout,
      component: Ranking,
      exact: true,
      titleMessage: 'Ranking',
      private: true,
      isAdmin: true
    },
    {
      path: '/participaciones',
      layout: HomeBaseLayout,
      component: Participaciones,
      exact: true,
      titleMessage: 'Participaciones',
      private: true,
      isAdmin: true
    },
    {
      path: '/participaciones/:id',
      layout: HomeBaseLayout,
      component: ParticipacionesDetail,
      exact: true,
      titleMessage: 'Participaciones',
      private: true,
      isAdmin: true
    },
    {
      path: '/participantes',
      layout: HomeBaseLayout,
      component: Participantes,
      exact: true,
      titleMessage: 'Participantes',
      private: true,
      isAdmin: true
    },
    {
      path: '/participantes/:id',
      layout: HomeBaseLayout,
      component: ParticipanteDetail,
      exact: true,
      titleMessage: 'Participantes',
      private: true,
      isAdmin: true
    },
    {
      path: '/premios',
      layout: HomeBaseLayout,
      component: Dashboard,
      exact: true,
      titleMessage: 'Premios',
      private: true,
      isAdmin: true
    },
    {
      path: '/usuarios',
      layout: HomeBaseLayout,
      component: Usuarios,
      exact: true,
      titleMessage: 'Usuarios',
      private: true,
      isAdmin: true
    },
    {
      path: '/usuarios/:id',
      layout: HomeBaseLayout,
      component: UsuarioDetail,
      exact: true,
      titleMessage: 'Usuarios',
      private: true,
      isAdmin: true
    },
    {
      layout: FullScreenLayout,
      component: Error404,
      titleMessage: '404'
    }
  ],
  public: [
    {
      path: '/login',
      layout: FullScreenLayout,
      component: Login,
      exact: true,
      titleMessage: 'Login',
      isAdmin: false
    },
    {
      layout: FullScreenLayout,
      component: Error404,
      titleMessage: '404'
    }
  ]
};
