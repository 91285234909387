import React, { useEffect, useState } from 'react';
import { Alert, Card, CardContent, Grid, Paper, Avatar, Typography } from '@mui/material';
import { Table } from '../Table';
import { useApi } from '../../hooks';
import { useParams } from 'react-router-dom';
import { Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const UsuarioDetail = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [usuarioData, setUsuarioData] = useState({});

  const [getUsuario] = useApi({
    endpoint: 'auth/dash/detail',
    method: 'get'
  });


  const getUsuarioDetail = async () => {
    const response = await getUsuario({ urlParams: id });
    setUsuarioData(response.data)
  };

  useEffect(() => {
    getUsuarioDetail();
  }, []);

  return usuarioData
    ?
    <Grid container spacing={2} >
      <Grid item xs={0} md={4}></Grid>
      <Grid item xs={12} md={4}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2}>
              <Grid container item xs={12} justifyContent={'center'}>
                <Avatar
                  sx={{ width: 120, height: 120 }}
                >
                  <Person sx={{ fontSize: 100 }} />
                </Avatar>
              </Grid>

              <Grid item xs={2} md={3}></Grid>
              <Grid item xs={4} md={3}>
                <Typography variant={'body1'} fontWeight={'bold'}>Nombre:</Typography>
              </Grid>
              <Grid item xs={4} md={3}>
                <Typography variant={'body2'}>{usuarioData.name}</Typography>
              </Grid>
              <Grid item xs={2} md={3}></Grid>

              <Grid item xs={2} md={3}></Grid>
              <Grid item xs={4} md={3}>
                <Typography variant={'body1'} fontWeight={'bold'}>Email:</Typography>
              </Grid>
              <Grid item xs={4} md={3}>
                <Typography variant={'body2'}>{usuarioData.email}</Typography>
              </Grid>
              <Grid item xs={2} md={3}></Grid>

              <Grid item xs={2} md={3}></Grid>
              <Grid item xs={4} md={3}>
                <Typography variant={'body1'} fontWeight={'bold'}>Fecha de creación:</Typography>
              </Grid>
              <Grid item xs={4} md={3}>
                <Typography variant={'body2'}>{new Date(usuarioData.createdDate).toLocaleDateString()}</Typography>
              </Grid>
              <Grid item xs={2} md={3}></Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={0} md={4}></Grid>
    </Grid>
    :
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={2} direction={'row'}>
              <Grid item xs={12}>
                <Paper variant='outlined' sx={{ padding: 1 }}>
                  <Typography variant='subtitle1' fontWeight={'bold'}>No se encontró información</Typography>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
};


export { UsuarioDetail };