import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registrar los componentes que vas a usar
ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const StackBarChart = ({ labels, data }) => {
  const dataBar = {
    labels: labels,
    datasets: [
      {
        label: 'Activia',
        borderColor: 'rgba(0, 183, 255, 1)',
        backgroundColor: 'rgba(0, 183, 255, 1)',
        data: data.activia
      },
      {
        label: 'Ben G',
        borderColor: 'rgba(250, 73, 255, 1)',
        backgroundColor: 'rgba(250, 73, 255, 1)',
        data: data.beng
      },
      {
        label: 'Danette',
        borderColor: 'rgba(70, 76, 206, 1)',
        backgroundColor: 'rgba(70, 76, 206, 1)',
        data: data.danette
      },
      {
        label: 'Danmix',
        borderColor: 'rgba(0, 236, 201, 1)',
        backgroundColor: 'rgba(0, 236, 201, 1)',
        data: data.danmix
      },
      {
        label: 'Danone',
        borderColor: 'rgba(0, 239, 94, 1)',
        backgroundColor: 'rgba(0, 239, 94, 1)',
        data: data.danone
      },
      {
        label: 'Danonino',
        borderColor: 'rgba(0, 239, 94, 1)',
        backgroundColor: 'rgba(0, 239, 94, 1)',
        data: data.danonino
      },
      {
        label: 'Danup',
        borderColor: 'rgba(255, 0, 54, 1)',
        backgroundColor: 'rgba(255, 0, 54, 1)',
        data: data.danup
      },
      {
        label: 'Dany',
        borderColor: 'rgba(255, 56, 21, 1)',
        backgroundColor: 'rgba(255, 56, 21, 1)',
        data: data.dany
      },
      {
        label: 'Oikos',
        borderColor: 'rgba(255, 169, 88, 1)',
        backgroundColor: 'rgba(255, 169, 88, 1)',
        data: data.oikos
      },
      {
        label: 'Vitalinea',
        borderColor: 'rgba(27, 47, 117, 1)',
        backgroundColor: 'rgba(27, 47, 117, 1)',
        data: data.vitalinea
      }
    ],
  };

  const options = {
    responsive: true,
    indexAxis: 'y',
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Productos registrados por día',
      }
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }

  };

  return <Bar data={dataBar} options={options} style={{ maxHeight: 400 }
  } />;
};


export { StackBarChart };