import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Registrar los componentes que vas a usar
ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const BarChart = ({ labels, data }) => {
  const dataBar = {
    labels: labels,
    datasets: [
      {
        label: 'Participantes',
        borderColor: 'rgba(46, 128, 153, 1)',
        backgroundColor: 'rgba(46, 128, 153, 1)',
        data: data.statesParticipantes
      },
      {
        label: 'Participaciones',
        borderColor: 'rgba(27, 47, 117, 1)',
        backgroundColor: 'rgba(27, 47, 117, 1)',
        data: data.statesParticipaciones
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Participantes y Participaciones por Estado',
      },
    },
  };

  return <Bar data={dataBar} options={options} style={{ maxHeight: 400 }} />;
};


export { BarChart };